$(document).ready(function(){
    $('BODY').on('click', '.is-invalid', function(){
        $(this).removeClass('is-invalid');
    })

    const Toast = Swal.mixin({
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });

    // IMAGES DRAG N DROP AND CHANGEd
    jQuery(document).on('change', 'INPUT.imgPreviewInput', function () {
        //console.log('Работа превью');
        var usObj = jQuery(this),
            input = jQuery(this)[0];
        if (input.files && input.files[0]) {
            if (input.files[0].type.match('image.*')) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    usObj.parents('.imgPreview').css('background-image', 'url('+e.target.result+')');
                }
                reader.readAsDataURL(input.files[0]);
            } else {
                jQuery(this).parents('.imgPreview').css('background-image', config('app.no_image'));
                //console.log('ошибка, не изображение');
            }
        } else {
            jQuery(this).parents('.imgPreview').css('background-image', config('app.no_image'));
            //console.log('хьюстон у нас проблема');
        }
    });

    jQuery('BODY').on('keypress', 'INPUT.numberInput', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0', '.'];

        //console.log(String.fromCharCode(e.which));
        //console.log(list.indexOf(String.fromCharCode(e.which)));
        if(String.fromCharCode(e.which) == '.'){
            if(val.length <= 0){
                val = '0.';
                jQuery(this).val('0.');
            }
            if(val.indexOf('.') >= 0){
                return false;
            }
        }
        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });

    jQuery('BODY').on('keypress', 'INPUT.numberInputInt', function(e){
        var val = jQuery(this).val(),
            list = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'];

        if(list.indexOf(String.fromCharCode(e.which)) < 0){
            return false;
        }
    });

    jQuery('BODY').on('change', 'INPUT.numberInput, INPUT.numberInputInt', function(e){
        var numberText = jQuery(this).val();
        numberText = numberText.replace(/ /, '');
        numberText = parseFloat(numberText);
        if(isNaN(numberText)){
            numberText = 0;
        }
        jQuery(this).val(numberText);
        return false;
    });


    // ARTICLE CONSTRUCTOR
    window.setTemplate = function(idType, blockInsert, oldData = null){
        jQuery.ajax({
            // url    : "{{ route('components.freeBlock.getViewTemplate') }}",
            url    : "/components/free-block/get-view-template",
            type   : 'POST',
            //dataType : 'json',
            data : {data: JSON.stringify({idTypeBlock: idType, oldData: oldData})},
            // headers: { 'X-CSRF-Token': "{{ csrf_token() }}" },
            headers: { 'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') },
            cache  : false,
            //success: function(data){}
        })
        .done( function(data){
                // console.log(data);
                //data = JSON.parse(data);
                if( data.status ){
                    console.log(data.message);
                    // Swal.fire({position: "top-right", title: data.message, icon: "success", timer: 1000, showConfirmButton: false});
                    Toast.fire({icon: 'success', title: data.message});
                    blockInsert.html(data.data.view);
                } else {
                    blockInsert.html('');
                    Swal.fire("Ошибка!", data.message, "error");
                }
            }
        )
        .fail(function(data){
            console.log(data);
            Swal.fire("Ошибка!", data.message, "error");
            }
        );
    }
    window.getCardBlock = function(idBlock, blockInsert){
        jQuery.ajax({
            // url    : "{{ route('components.freeBlock.getViewTemplate') }}",
            url    : "/components/free-block/get-view-card",
            type   : 'POST',
            //dataType : 'json',
            data : {data: JSON.stringify({idFreeBlock: idBlock})},
            // headers: { 'X-CSRF-Token': "{{ csrf_token() }}" },
            headers: { 'X-CSRF-Token': jQuery('meta[name="csrf-token"]').attr('content') },
            cache  : false,
            //success: function(data){}
        })
        .done( function(data){
                // console.log(data);
                //data = JSON.parse(data);
                if( data.status ){
                    console.log(data.message);
                    // Swal.fire({position: "top-right", title: data.message, icon: "success", timer: 1000, showConfirmButton: false});
                    Toast.fire({icon: 'success', title: data.message});
                    blockInsert.append(data.data.view);
                } else {
                    blockInsert.html('');
                    Swal.fire("Ошибка!", data.message, "error");
                }
            }
        )
        .fail(function(data){
            console.log(data);
            Swal.fire("Ошибка!", data.message, "error");
            }
        );
    }

    
});




